import React from "react";
import { v1 as uuid } from "uuid";

const CreateRoom = (props) => {
  


    function create() {
        const id = uuid();
        props.history.push(`/room/${id}`);
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "linear-gradient(to bottom, grey, black)" // Background gradient
        }}>
            <button onClick={create} style={{
                fontFamily: "Product Sans",
                backgroundColor: "transparent", // Make the button background transparent
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid white" // Add border to the button
            }}>Create Room</button>
        </div>
    );
}

export default CreateRoom;