import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MicIcon from '@mui/icons-material/Mic';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import VideocamIcon from '@mui/icons-material/Videocam';
import { CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import io from 'socket.io-client';
import ProfileImage from '../images/profile.png';

const connectionURL = process.env.REACT_APP_SERVER_URL;
const profileContainerStyle = {
    width: '50px',
    height: '50px',
    alignContent: 'center', alignItems: 'center',
    borderRadius: "50px",
    backgroundColor: '#1f1f1f', // Dark gray background close to black
    overflow: 'hidden', // Ensure the image stays within the container
};

const Room = (props) => {
    const partnerVideo = useRef();
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();
    const userStream = useRef();
    const senders = useRef([]);

    const [isMicOn, setIsMicOn] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [joinedUsers, setJoinedUsers] = useState([]); 

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
            userStream.current = stream;

            userStream.current.getVideoTracks().forEach(track => {
                track.enabled = false;
            });

            const newSocket = io(`${connectionURL}`);
            socketRef.current = newSocket;
            socketRef.current.emit("join room", props.match.params.roomID);
            socketRef.current.on('other user', userID => {
                callUser(userID);
                otherUser.current = userID;
            });
            socketRef.current.on("user joined", userID => {
                // Add the joined user to the list of joined users if not already added
                if (!joinedUsers.includes(userID)) {
                    setJoinedUsers(prevUsers => [...prevUsers, userID]);
                    setTimeout(() => {
                    }, 3000);
                }
            });
            socketRef.current.on("offer", handleRecieveCall);
            socketRef.current.on("answer", handleAnswer);
            socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }).catch(error => {
            console.error("Error accessing media devices:", error);
        });

        return () => {
            // Cleanup function to remove socket event listeners
            socketRef.current.off("other user");
            socketRef.current.off("user joined");
            socketRef.current.off("offer");
            socketRef.current.off("answer");
            socketRef.current.off("ice-candidate");
        };
    },[]);


    // Ensure that peerRef.current is initialized only once
    useEffect(() => {
        if (joinedUsers.length > 1) {
            // Create peer connection only when there are two users in the room
            peerRef.current = createPeer(otherUser.current);
        }
    }, [joinedUsers]);


    function callUser(userID) {
        peerRef.current = createPeer(userID);
        userStream.current.getTracks().forEach(track => senders.current.push(peerRef.current.addTrack(track, userStream.current)));
    }

    function createPeer(userID) {
        const peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "stun:stun.stunprotocol.org"
                },
                {
                    urls: 'turn:numb.viagenie.ca',
                    credential: 'muazkh',
                    username: 'webrtc@live.com'
                },
            ]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    }

    function handleNegotiationNeededEvent(userID) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleRecieveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.current.createAnswer();
        }).then(answer => {
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        })
    }



    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    function handleTrackEvent(e) {
        partnerVideo.current.srcObject = e.streams[0];
    };

    function handleShareScreen() {
        navigator.mediaDevices.getDisplayMedia({ cursor: true }).then(stream => {
            const screenTrack = stream.getTracks()[0];
            senders.current = peerRef?.current?.getSenders(); // Initialize senders
            senders.current?.find(sender => sender.track.kind === 'video').replaceTrack(screenTrack);
            screenTrack.onended = function() {
                senders.current.find(sender => sender.track.kind === "video").replaceTrack(userStream.current.getTracks()[1]);
            }
        })
    }

    const handleLeaveRoom = () => {
        try {
            window.close(); // Close the current tab
        } catch (error) {
            console.log("Error while closing the tab:", error);
        }
    };


    const toggleMic = () => {
        const newValue = !isMicOn;
        userStream.current.getAudioTracks().forEach(track => {
            track.enabled = newValue; // Set the enabled state of the audio track
        });
        setIsMicOn(newValue); // Update the state variable
    };

    const toggleCamera = () => {
        const newValue = !isCameraOn;
        userStream.current.getVideoTracks().forEach(track => {
            track.enabled = newValue; // Set the enabled state of the video track
        });
        setIsCameraOn(newValue); // Update the state variable
    };



    return (
        <div style={{ backgroundColor: "black", textAlign: "center", paddingTop: "5px" }}>
          
          <div style={{ backgroundColor: "black", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            {joinedUsers.length > 0 ? (
                joinedUsers.map((userID, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center" }}>
                        <div style={profileContainerStyle}>
                            <img src={ProfileImage} alt="Profile" style={{ width: '100%', height: '100%' }} />
                        </div>
                        <p style={{ color: '#fff', fontFamily: 'Product Sans', marginLeft: "10px" }}>Client has joined the room </p>
                        <CheckCircleIcon style={{ color: 'green', marginLeft: "5px" }} />
                    </div>
                ))
            ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}>
                        {/* Conditionally render CircularProgress only if there are no joined users */}
                        {!joinedUsers.length && <CircularProgress color="secondary" size={30} />}
                    </div>
                </div>
            )}
        </div>


            <video muted style={{ height: 800, width: 1200 }} autoPlay ref={partnerVideo} />
            <div>
                <IconButton onClick={toggleMic} style={{ margin: "0 20px", borderRadius: "50%" }}>
                    <MicIcon style={{ color: isMicOn ? 'white' : '#808080' }} />
                </IconButton>
                <IconButton onClick={toggleCamera} style={{ margin: "0 20px", borderRadius: "50%" }}>
                    <VideocamIcon style={{ color: isCameraOn ? 'white' : '#808080' }} />
                </IconButton>
                <IconButton onClick={handleShareScreen} style={{ margin: "0 20px", borderRadius: "50%" }}>
                    <ScreenShareIcon style={{ fontSize: '55px', color: 'white' }} />
                </IconButton>
                <IconButton onClick={handleLeaveRoom} style={{ background: 'red', margin: "0 20px", borderRadius: "50%" }}>
                    <CallIcon style={{ color: 'white' }} />
                </IconButton>
                <IconButton style={{ margin: "0 20px", borderRadius: "50%" }}>
                    <MoreVertIcon style={{ color: 'white' }} />
                </IconButton>
            </div>
        </div>
    );
};

export default Room;
